import React, { useState, useContext } from 'react';
import LogoutButton from '../../components/LogoutButton';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
} from '@material-ui/core';
import { AuthContext } from '../../helpers/context/auth-context';

const Login = () => {
    const { token } = useContext(AuthContext);
    const { setToken } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: '/' } };

    const onSubmitForm = () => {
        if (username.length > 3 && password.length > 3) {
            axios
                .post('https://api.lillesandrk.no/v1/auth', {
                    username: username,
                    password: password,
                })
                .then((response) => {
                    localStorage.setItem('token', response.data.token);
                    setUsername('');
                    setPassword('');
                    setToken(response.data.token);
                    history.replace(from);
                })
                .catch((error) => {
                    setError('Innlogging feilet.');
                });
        } else {
            setError('Brukernavn og/eller passord er for kort');
        }
    };
    return (
        <React.Fragment>
            <Card style={{ marginTop: '20%' }}>
                <CardHeader title='Inn/utlogging' />
                <CardContent>
                    {!token ? (
                        <form onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                id='username'
                                type='text'
                                label='Brukernavn'
                                variant='outlined'
                                autoComplete='current-username'
                                fullWidth
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={{ marginBottom: 20 }}
                            />
                            <TextField
                                id='password'
                                type='password'
                                label='Passord'
                                variant='outlined'
                                autoComplete='current-password'
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ marginBottom: 20 }}
                            />
                            <Button
                                color='primary'
                                fullWidth
                                variant='contained'
                                size='large'
                                type='submit'
                                onClick={onSubmitForm}
                            >
                                Logg inn
                            </Button>
                        </form>
                    ) : (
                        <LogoutButton />
                    )}
                    {error && (
                        <Alert
                            style={{ marginTop: 40 }}
                            severity='error'
                            onClose={() => {
                                setError(null);
                            }}
                        >
                            {error}
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default Login;
