import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const CustomAlert = (props) => {
    if (props.alert.title && props.alert.messages && props.alert.type) {
        return (
            <Alert
                style={{ marginTop: 10, marginBottom: 10 }}
                severity={props.alert.type}
                onClose={() => {
                    props.setAlert();
                }}
            >
                <AlertTitle>{props.alert.title}</AlertTitle>
                {props.alert.messages.map((message, index) => {
                    return (
                        <Typography key={index} variant='body1'>
                            {message}...
                        </Typography>
                    );
                })}
            </Alert>
        );
    } else {
        return null;
    }
};

export default CustomAlert;
