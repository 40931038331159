import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody,
    Checkbox,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
const ChecklistCat = ({ category, items, handleCheck }) => {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>{category.name}:</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Sjekkpunkt</TableCell>
                                <TableCell>Verdi</TableCell>
                                <TableCell>Ansvar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => {
                                if (item.status === 1) {
                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            onClick={(evt) => {
                                                handleCheck(evt, item.id);
                                            }}
                                            tabIndex={-1}
                                            key={item.id}
                                            selected={
                                                item.checked ? true : false
                                            }
                                        >
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        item.checked
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {item.elementName}
                                            </TableCell>
                                            <TableCell>
                                                {item.elementValue}
                                            </TableCell>
                                            <TableCell>
                                                {item.elementRes}
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default ChecklistCat;
