import React, { createContext, useState } from 'react';
import axios from 'axios';
export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const prevToken = window.localStorage.getItem('token') || null;
    const [token, setToken] = useState(prevToken);
    //axios.defaults.baseURL = 'http://192.168.39.162:8080/';
    axios.defaults.baseURL = 'https://api.lillesandrk.no/';
    axios.defaults.headers.common['x-access-auth'] = token;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const defaultContext = {
        token,
        setToken,
    };

    return (
        <AuthContext.Provider value={defaultContext}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
