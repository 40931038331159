import React from 'react';
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const NewLogEntryDialog = (props) => {
    return (
        <Dialog
            id='logEntryDialog'
            open={props.newLogEntryDialogOpen}
            onClose={() => {
                props.setNewLogEntryDialogOpen(false);
                props.setLogEntry('');
                props.setLogEntryType(null);
            }}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Ny loggføring:</DialogTitle>
            <DialogContent>
                <TextField
                    id='logEntryInput'
                    error={props.logEntryHelper ? true : false}
                    helperText={props.logEntryHelper}
                    variant='outlined'
                    label='Loggføring'
                    multiline
                    rows='4'
                    fullWidth
                    value={props.logEntry}
                    onChange={(e) => props.setLogEntry(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Autocomplete
                    id='logTypeInput'
                    value={props.logEntryType}
                    options={props.logTypeList}
                    noOptionsText='Ingen treff'
                    onChange={(e, val) => props.setLogEntryType(val)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.logEntryTypeHelper ? true : false}
                            helperText={props.logEntryTypeHelper}
                            label='Loggtype'
                            variant='outlined'
                            style={{ marginBottom: 10 }}
                        />
                    )}
                />
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='primary'
                            onClick={props.handleNewLog}
                        >
                            Lagre
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='secondary'
                            onClick={() => {
                                props.setNewLogEntryDialogOpen(false);
                                props.setLogEntry('');
                                props.setLogEntryType(null);
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default NewLogEntryDialog;
