import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';

const NewStatusDialog = (props) => {
    return (
        <Dialog
            id='statusDialog'
            open={props.newStatusDialogOpen}
            onClose={() => {
                props.setNewStatusDialogOpen(false);
            }}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Status</DialogTitle>
            <DialogContent>
                {props.statusList.map((status) => (
                    <Button
                        key={status.id}
                        variant='contained'
                        color='primary'
                        data-status={status.name}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        size='large'
                        onClick={props.handleNewStatus}
                    >
                        {status.name}
                    </Button>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default NewStatusDialog;
