import React, { useState, useEffect } from 'react';
import ChecklistCat from '../../components/ChecklistCat';
import Axios from 'axios';
import {
    Card,
    CardContent,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
} from '@material-ui/core';

const Checklist = () => {
    const [shouldRefreshItems, setShoudlRefreshItems] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [checklists, setChecklists] = useState([]);
    const [checklistItems, setChecklistItems] = useState([]);

    useEffect(() => {
        Axios.get('v1/checklistcat')
            .then((res) => {
                setChecklists(res.data);
                localStorage.setItem(
                    'checkCat',
                    btoa(JSON.stringify(res.data))
                );
            })
            .catch((err) => {
                console.log(err);
                if (localStorage.getItem('checkCat')) {
                    setChecklists(
                        JSON.parse(atob(localStorage.getItem('checkCat')))
                    );
                }
            });
    }, []);
    useEffect(() => {
        if (shouldRefreshItems) {
            Axios.get('v1/checklist')
                .then((res) => {
                    setChecklistItems(res.data);
                    setShoudlRefreshItems(false);
                    localStorage.setItem(
                        'checklist',
                        btoa(JSON.stringify(res.data))
                    );
                })
                .catch((err) => {
                    console.log(err);
                    setShoudlRefreshItems(false);
                    if (localStorage.getItem('checklist')) {
                        setChecklistItems(
                            JSON.parse(atob(localStorage.getItem('checklist')))
                        );
                    }
                });
        }
    }, [shouldRefreshItems]);
    const handleCheck = (evt, id) => {
        const active = checklistItems.filter((item) => {
            return id === item.id;
        });
        if (active.length === 1) {
            const payload = {
                id: id,
                checked: active[0].checked ? 0 : 1,
            };
            Axios.patch('v1/checklist', payload)
                .then((res) => {
                    setShoudlRefreshItems(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleResetAll = () => {
        Axios.patch('v1/reset-checklist')
            .then((res) => {
                setShoudlRefreshItems(true);
                setDialogOpen(false);
            })
            .catch((err) => {
                console.log(err);
                setDialogOpen(false);
            });
    };
    return (
        <React.Fragment>
            <Card style={{ marginTop: 20, marginBottom: 5 }}>
                <CardContent>
                    <Button
                        fullWidth
                        color='secondary'
                        variant='contained'
                        onClick={() => setDialogOpen(true)}
                    >
                        Nullstill alle sjekklister
                    </Button>
                </CardContent>
            </Card>
            <Dialog
                onClose={() => {
                    setDialogOpen(false);
                }}
                open={dialogOpen}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle>Er du sikker?</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} style={{ marginBottom: 20 }}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant='contained'
                                fullWidth
                                size='large'
                                color='primary'
                                onClick={handleResetAll}
                            >
                                Ja
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant='contained'
                                fullWidth
                                size='large'
                                color='secondary'
                                onClick={() => {
                                    setDialogOpen(false);
                                }}
                            >
                                Nei
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {checklists.map((checklist) => {
                if (checklist.status === 1) {
                    return (
                        <ChecklistCat
                            key={checklist.id}
                            category={checklist}
                            items={checklistItems.filter((res) => {
                                return res.categoryId === checklist.id;
                            })}
                            handleCheck={handleCheck}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </React.Fragment>
    );
};

export default Checklist;
