import React from 'react';
import { Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const CrewPickerCard = (props) => {
    return (
        <Card style={{ marginTop: 20 }}>
            <CardHeader title='Velg Mannskap' />
            <CardContent>
                <Autocomplete
                    id='startCrew1'
                    value={props.startCrew1}
                    options={props.crewList1}
                    noOptionsText='Ingen treff'
                    getOptionDisabled={(option) =>
                        option === props.startCrew2 ||
                        option === props.startCrew3 ||
                        option === props.startCrew4
                    }
                    onChange={(e, val) => props.setStartCrew1(val)}
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startCrew1Helper ? true : false}
                            helperText={props.startCrew1Helper}
                            label='Båtfører'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew2'
                    options={props.crewList2}
                    value={props.startCrew2}
                    noOptionsText='Ingen treff'
                    getOptionDisabled={(option) =>
                        option === props.startCrew1 ||
                        option === props.startCrew3 ||
                        option === props.startCrew4
                    }
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, val) => props.setStartCrew2(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startCrew2Helper ? true : false}
                            helperText={props.startCrew2Helper}
                            label='Navigatør'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew3'
                    options={props.crewList3}
                    value={props.startCrew3}
                    getOptionDisabled={(option) =>
                        option === props.startCrew1 ||
                        option === props.startCrew2 ||
                        option === props.startCrew4
                    }
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, val) => props.setStartCrew3(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startCrew3Helper ? true : false}
                            helperText={props.startCrew3Helper}
                            label='Førstehjelper'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew4'
                    options={props.crewList4}
                    noOptionsText='Ingen treff'
                    value={props.startCrew4}
                    getOptionLabel={(option) => option.fullName}
                    getOptionDisabled={(option) =>
                        option === props.startCrew1 ||
                        option === props.startCrew2 ||
                        option === props.startCrew3
                    }
                    onChange={(e, val) => props.setStartCrew4(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startCrew4Helper ? true : false}
                            helperText={props.startCrew4Helper}
                            label='Mannskap'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
            </CardContent>
        </Card>
    );
};

export default CrewPickerCard;
