import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomeStatusCard from '../../components/Cards/HomeStatusCard';
import Loading from '../../components/common/Loading';

const Home = () => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const [lastMissionData, setLastMissionData] = useState({});
    const [maintList, setMaintList] = useState([]);

    const fetchLastMission = () => {
        setLoading(true);
        axios
            .get('v1/lastmission')
            .then((response) => {
                setLoading(false);
                localStorage.setItem(
                    'lastMission',
                    btoa(JSON.stringify(response.data[0]))
                );
                setLastMissionData(response.data[0]);
            })
            .catch((error) => {
                setLoading(false);
                setError(
                    'Kunne ikke laste data fra server. Lokale data blir brukt...'
                );
                const localMissionData =
                    JSON.parse(atob(localStorage.getItem('lastMission'))) ||
                    null;
                setLastMissionData(localMissionData);
            });
    };
    const fetchMaintenanceList = () => {
        setLoading(true);
        axios
            .get('/v1/maintenance/')
            .then((res) => {
                if (res.status === 204) {
                } else {
                    setMaintList(res.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setMaintList([]);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchLastMission();
        fetchMaintenanceList();
    }, []);

    return (
        <React.Fragment>
            {loading && <Loading />}
            {!loading && (
                <HomeStatusCard
                    lastMissionData={lastMissionData}
                    error={error}
                    setError={setError}
                    maintList={maintList}
                />
            )}
        </React.Fragment>
    );
};

export default Home;
