import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const StartMissionCard = (props) => {
    return (
        <Card style={{ marginTop: 20 }}>
            <CardHeader title='Start nytt oppdrag' />
            <CardContent>
                <TextField
                    error={props.startLocHelper ? true : false}
                    helperText={props.startLocHelper}
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={props.startLoc}
                    onChange={(e) => props.setStartLoc(e.target.value)}
                    id='startLocation'
                    label='Oppstartslokasjon'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    error={props.startEngHelper ? true : false}
                    helperText={props.startEngHelper}
                    variant='outlined'
                    type='number'
                    fullWidth
                    id='startEngineHours'
                    label='Motortimer ved start'
                    value={props.startEng}
                    onChange={(e) => props.setStartEng(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Autocomplete
                    id='missionCat'
                    options={props.missionTypeList}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.name}
                    value={props.startMissionType}
                    onChange={(e, val) => props.setStartMissionType(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startMissionTypeHelper ? true : false}
                            helperText={props.startMissionTypeHelper}
                            label='Oppdragstype'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Button
                    variant='contained'
                    fullWidth
                    color='primary'
                    size='large'
                    onClick={props.handleStartMission}
                >
                    Start oppdrag
                </Button>
            </CardContent>
        </Card>
    );
};

export default StartMissionCard;
