import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';
import { AuthContext } from '../helpers/context/auth-context';
const LogoutButton = () => {
    const { token, setToken } = useContext(AuthContext);
    let history = useHistory();
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleSignout = () => {
        localStorage.removeItem('token');
        setToken(null);
        history.push('/innlogging');
    };
    if (token) {
        return (
            <React.Fragment>
                <Button
                    variant='contained'
                    fullWidth
                    color='secondary'
                    onClick={handleClickOpen}
                    style={{ marginTop: 50 }}
                >
                    Logg ut
                </Button>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby='Logg ut?'
                    aria-describedby='Vil du virkelig logge ut?'
                >
                    <DialogTitle id='logout-dialog-title'>
                        Vil du virkelig logge ut?
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={handleSignout}
                        >
                            Ja
                        </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={handleClose}
                        >
                            Nei
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    } else {
        return <div />;
    }
};

export default LogoutButton;
