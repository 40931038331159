import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    BottomNavigation,
    BottomNavigationAction,
    AppBar,
    makeStyles,
} from '@material-ui/core';
import {
    Home as HomeIcon,
    Cloud as CloudIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    LocalGasStation as LocalGasStationIcon,
    DirectionsBoat as DirectionsBoatIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
}));

const Footer = () => {
    const classes = useStyles();
    const [menuValue, setMenuValue] = useState(() => {
        let menuItem = 'hjem';
        if (sessionStorage.getItem('menuItem')) {
            menuItem = sessionStorage.getItem('menuItem');
        }
        return menuItem;
    });
    return (
        <AppBar position='fixed' color='primary' className={classes.appBar}>
            <BottomNavigation
                value={menuValue}
                onChange={(event, newMenuValue) => {
                    setMenuValue(newMenuValue);
                    sessionStorage.setItem('menuItem', newMenuValue);
                }}
            >
                <BottomNavigationAction
                    label='Hjem'
                    icon={<HomeIcon />}
                    value='hjem'
                    component={Link}
                    to='/'
                />
                <BottomNavigationAction
                    label='Oppdrag'
                    icon={<DirectionsBoatIcon />}
                    value='oppdrag'
                    component={Link}
                    to='/oppdrag'
                />
                <BottomNavigationAction
                    label='Vær'
                    icon={<CloudIcon />}
                    value='vaer'
                    component={Link}
                    to='/vaer'
                />
                <BottomNavigationAction
                    label='Sjekkliste'
                    icon={<AssignmentTurnedInIcon />}
                    value='sjekkliste'
                    component={Link}
                    to='/sjekkliste'
                />
                <BottomNavigationAction
                    label='Drivstoff'
                    icon={<LocalGasStationIcon />}
                    value='drivstoff'
                    component={Link}
                    to='/drivstoff'
                />
            </BottomNavigation>
        </AppBar>
    );
};

export default Footer;
