import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Divider,
} from '@material-ui/core';
import {
    DirectionsBoat as DirectionsBoatIcon,
    DateRange as DateRangeIcon,
    Person as PersonIcon,
    Build as BuildIcon,
} from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import rclImage from '../../assets/images/rcl-nla.png';
import moment from 'moment';

const HomeStatusCard = ({ maintList, lastMissionData, error, setError }) => {
    moment.updateLocale('nb', {
        invalidDate: 'Ugyldig dato',
    });
    const filteredMaintList = maintList.filter((listelm) => {
        return (
            moment().isBetween(
                moment(listelm.nextCheck).subtract(listelm.notice, 'days'),
                moment(listelm.nextCheck)
            ) || moment(listelm.nextCheck).isBefore(moment())
        );
    });
    let missionStatusText;
    if (lastMissionData) {
        if (lastMissionData.id && !lastMissionData.stopTime) {
            missionStatusText = (
                <React.Fragment>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DirectionsBoatIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'RC Lillesand er for øyeblikket ute på ' +
                                    lastMissionData.missionCatName
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Oppdraget ble påbegynt ' +
                                    moment(lastMissionData.startTime).format(
                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Båtfører er ' + lastMissionData.crew1Name
                                }
                            />
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        } else {
            missionStatusText = (
                <React.Fragment>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DirectionsBoatIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'RC Lillesand er for øyeblikket ledig ved ' +
                                    lastMissionData.stopLoc
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Siste oppdrag ble avsluttet ' +
                                    moment(lastMissionData.stopTime).format(
                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Båtfører siste oppdrag var ' +
                                    lastMissionData.crew1Name
                                }
                            />
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <Card style={{ marginTop: 20 }}>
                <CardMedia component='img' image={rclImage} width='100%' />
                <CardHeader title='Velkommen til RC Lillesand' />
                <CardContent>
                    {error && (
                        <Alert
                            severity='warning'
                            onClose={() => {
                                setError();
                            }}
                        >
                            <AlertTitle>Wooopsie....</AlertTitle>
                            {error}
                        </Alert>
                    )}
                    <Typography variant='body1' component='p'>
                        Dette er en webapp for oppdragshåndtering for RC
                        Lillesand.
                    </Typography>
                    <Divider />
                    {filteredMaintList.length > 0 ? (
                        <React.Fragment>
                            <Typography variant='h5'>
                                Vedlikehold etc. (sjekkes ut fra portal):
                            </Typography>
                            <List>
                                {filteredMaintList.map((item) => {
                                    const maintStatus = moment().to(
                                        moment(item.nextCheck || moment())
                                    );
                                    return (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BuildIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.title}
                                                secondary={maintStatus}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                            <Divider />
                        </React.Fragment>
                    ) : null}
                    <Typography variant='h6'>Status RC Lillesand:</Typography>
                    {missionStatusText}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default HomeStatusCard;
