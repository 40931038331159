import React, { useState } from 'react';
import { Card, CardHeader, CardContent, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const weatherLocList = [
    { name: 'Lillesand Havn, Lillesand', position: '58,23404_8,41364' },
    { name: 'Lillesand Utaskjærs, Lillesand', position: '58,21418_8,45484' },
    { name: 'Skottevig, Lillesand', position: '58.10609_8.23678' },
    { name: 'Oksøy, Kristiansand', position: '58.06722_8.05980' },
    { name: 'Søgne, Kristiansand', position: '58.04103_7.86577' },
    { name: 'Mandal, Lindesnes', position: '58.00684_7.46727' },
    { name: 'Homborsund, Grimstad', position: '58.34753_8.67016' },
    { name: 'Hasseltangen, Grimstad', position: '58.40328_8.75945' },
    { name: 'Tromøya, Arendal', position: '58.44863_8.88493' },
    { name: 'Sandøya, Tvedestrand', position: '58.61873_9.14392' },
    { name: 'Risør, Risør', position: '58.67416_9.23491' },
    { name: 'Kragerø, Kragerø', position: '58.83656_9.52194' },
];

const Weather = () => {
    const [weatherLoc, setWeatherLoc] = useState();
    return (
        <React.Fragment>
            <Card style={{ marginTop: 20 }}>
                <CardHeader title='Værmelding' />
                <CardContent>
                    <Autocomplete
                        id='weatherLoc'
                        options={weatherLocList}
                        noOptionsText='Ingen treff'
                        getOptionLabel={(option) => option.name}
                        onChange={(e, val) => setWeatherLoc(val)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Velg sted'
                                variant='outlined'
                                style={{ marginBottom: 20 }}
                            />
                        )}
                    />
                </CardContent>
            </Card>
            {weatherLoc && (
                <img
                    src={`https://www.yr.no/place/Ocean/58,23404_8,41364${weatherLoc.position}/marinogram.png`}
                    alt='marinogram'
                    style={{ width: '100%' }}
                />
            )}
        </React.Fragment>
    );
};

export default Weather;
