import React from 'react';
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';

const EndMissionDialog = (props) => {
    return (
        <Dialog
            id='endMissionDialog'
            open={props.endMissionDialogOpen}
            onClose={() => props.setEndMissionDialogOpen(false)}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Avslutt oppdrag</DialogTitle>
            <DialogContent>
                <TextField
                    error={props.stopLocHelper ? true : false}
                    helperText={props.stopLocHelper}
                    fullWidth
                    variant='outlined'
                    label='Lokasjon ved avslutning'
                    value={props.stopLoc}
                    onChange={(e) => {
                        props.setStopLoc(e.target.value);
                    }}
                    type='text'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    error={props.stopEngHelper ? true : false}
                    helperText={props.stopEngHelper}
                    variant='outlined'
                    label={`Motortimer stopp(OBS, må være høyere enn eller lik ${props.missionData.startEngine}`}
                    value={props.stopEng}
                    onChange={(e) => {
                        props.setStopEng(e.target.value);
                    }}
                    type='number'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    error={props.stopDistHelper ? true : false}
                    helperText={props.stopDistHelper}
                    variant='outlined'
                    label='Nautiske mil på tur'
                    type='number'
                    value={props.stopDist}
                    onChange={(e) => {
                        props.setStopDist(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                />
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={props.handleEndMission}
                        >
                            Avslutt oppdrag
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setEndMissionDialogOpen(false);
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default EndMissionDialog;
