import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';

const Fuel = () => {
    const [fuelState, setFuelState] = useState();
    const [lastFuelState, setLastFuelState] = useState({});
    useEffect(() => {
        if (fuelState || fuelState === 0) {
            console.log(fuelState);
            Axios.post('v1/fuel', { level: fuelState })
                .then((res) => {
                    Axios.get('v1/fuel')
                        .then((res) => {
                            setLastFuelState(res.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {});
        } else {
            Axios.get('v1/fuel')
                .then((res) => {
                    setLastFuelState(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [fuelState]);
    let fuelMsg = '';
    if (lastFuelState.id) {
        fuelMsg = `Sist rapportert ${moment(lastFuelState.regTime).format(
            'DD.MM-YYYY[, kl. ]HH:mm:ss'
        )} med nivå på ${lastFuelState.level}%.`;
        if (lastFuelState.okBy) {
            fuelMsg += ` Siste rapport håndtert av ${lastFuelState.okBy}`;
        }
    }

    return (
        <React.Fragment>
            <Card style={{ marginTop: 20 }}>
                <CardHeader title='Meld drivstoff' />
                <CardContent>
                    <Typography variant='body1' style={{ marginBottom: 20 }}>
                        Her kan du melde nåværende drivstoffnivå til
                        driftsgruppa.
                        <br />
                        {fuelMsg}
                    </Typography>
                    <Button
                        fullWidth
                        size='large'
                        style={{
                            background: '#024E00',
                            marginBottom: 10,
                            padding: 30,
                        }}
                        onClick={() => {
                            setFuelState(100);
                        }}
                    >
                        100%
                    </Button>
                    <Button
                        fullWidth
                        size='large'
                        style={{
                            background: '#00832C',
                            marginBottom: 10,
                            padding: 30,
                        }}
                        onClick={() => {
                            setFuelState(75);
                        }}
                    >
                        75%
                    </Button>
                    <Button
                        fullWidth
                        size='large'
                        style={{
                            background: '#A2B000',
                            marginBottom: 10,
                            padding: 30,
                        }}
                        onClick={() => {
                            setFuelState(50);
                        }}
                    >
                        50%
                    </Button>
                    <Button
                        fullWidth
                        size='large'
                        style={{
                            background: '#963600',
                            marginBottom: 10,
                            padding: 30,
                        }}
                        onClick={() => {
                            setFuelState(25);
                        }}
                    >
                        25%
                    </Button>
                    <Button
                        fullWidth
                        size='large'
                        style={{
                            background: '#750000',
                            marginBottom: 10,
                            padding: 30,
                        }}
                        onClick={() => {
                            setFuelState(0);
                        }}
                    >
                        0%
                    </Button>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default Fuel;
