import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@material-ui/core';

const OfflineLogAlert = (props) => {
    return (
        <Alert
            severity='warning'
            action={
                <Button
                    color='inherit'
                    size='small'
                    onClick={props.handleSaveOfflineLog}
                >
                    LAGRE
                </Button>
            }
        >
            <AlertTitle>Oppdragslogg</AlertTitle>
            Det ligger innhold til loggføring i minnet som ikke er synkronisert
            med database ennå. Trykk på knappen til høyre for å forsøke å lagre
            igjen.
        </Alert>
    );
};

export default OfflineLogAlert;
