import React, { useState, useCallback, useEffect } from 'react';
import CrewPickerCard from '../../components/mission/CrewPickerCard';
import StartMissionCard from '../../components/mission/StartMissionCard';
import Axios from 'axios';
import MissionDataExp from '../../components/mission/MissionDataExp';
import MissionLogExp from '../../components/mission/MissionLogExp';
import EndMissionDialog from '../../components/mission/EndMissionDialog';
import NewLogEntryDialog from '../../components/mission/NewLogEntryDialog';
import NewStatusDialog from '../../components/mission/NewStatusDialog';
import CustomAlert from '../../components/common/CustomAlert';
import OfflineLogAlert from '../../components/mission/OfflineLogAlert';
import Loader from '../../components/common/Loading';
import ChangeCatDialog from '../../components/mission/ChangeCatDialog';

const MissionV2 = () => {
    const [alert, setAlert] = useState();
    const [loading, setLoading] = useState(false);
    const [endMissionDialogOpen, setEndMissionDialogOpen] = useState(false);
    const [changeCatDialogOpen, setChangeCatDialogOpen] = useState(false);
    const [newStatusDialogOpen, setNewStatusDialogOpen] = useState(false);
    const [newLogEntryDialogOpen, setNewLogEntryDialogOpen] = useState(false);
    const [offlineLog, setOfflineLog] = useState([]);

    const [missionData, setMissionData] = useState({});
    const [crewList1, setCrewList1] = useState([]);
    const [crewList2, setCrewList2] = useState([]);
    const [crewList3, setCrewList3] = useState([]);
    const [crewList4, setCrewList4] = useState([]);
    const [missionTypeList, setMissionTypeList] = useState([]);
    const [logTypeList, setLogTypeList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [missionLog, setMissionLog] = useState([]);
    //const [hankData, setHankData] = useState();

    const [startCrew1, setStartCrew1] = useState(null);
    const [startCrew2, setStartCrew2] = useState(null);
    const [startCrew3, setStartCrew3] = useState(null);
    const [startCrew4, setStartCrew4] = useState(null);
    const [startLoc, setStartLoc] = useState('');
    const [startEng, setStartEng] = useState('');
    const [startMissionType, setStartMissionType] = useState(null);
    const [startCrew1Helper, setStartCrew1Helper] = useState();
    const [startCrew2Helper, setStartCrew2Helper] = useState();
    const [startCrew3Helper, setStartCrew3Helper] = useState();
    const [startCrew4Helper, setStartCrew4Helper] = useState();
    const [startLocHelper, setStartLocHelper] = useState('');
    const [startEngHelper, setStartEngHelper] = useState('');
    const [startMissionTypeHelper, setStartMissionTypeHelper] = useState();

    const [stopLoc, setStopLoc] = useState('');
    const [stopLocHelper, setStopLocHelper] = useState();
    const [stopEng, setStopEng] = useState(0);
    const [stopEngHelper, setStopEngHelper] = useState();
    const [stopDist, setStopDist] = useState(0);
    const [stopDistHelper, setStopDistHelper] = useState();

    const [pob, setPob] = useState(0);
    const [crewN, setCrewN] = useState(0);
    const [logEntry, setLogEntry] = useState('');
    const [logEntryHelper, setLogEntryHelper] = useState();
    const [logEntryType, setLogEntryType] = useState(null);
    const [logEntryTypeHelper, setLogEntryTypeHelper] = useState();
    const fetchCrewList = () => {
        setLoading(true);
        Axios.get('v1/crew')
            .then((res) => {
                setCrewList1(res.data.filter((crew) => crew.crewLevel > 3));
                setCrewList2(res.data.filter((crew) => crew.crewLevel > 2));
                setCrewList3(res.data.filter((crew) => crew.crewLevel > 1));
                setCrewList4(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setAlert({
                    title: 'Feil under lasting!',
                    type: 'error',
                    messages: ['Feil under lasting av mannskapslister'],
                });
                setLoading(false);
            });
    };
    const fetchMissionData = () => {
        setLoading(true);
        Axios.get('v1/lastmission')
            .then((response) => {
                setMissionData(response.data[0]);
                setStopEng(response.data[0].startEngine);
                setStartLoc(response.data[0].stopLoc || '');
                setStartEng(response.data[0].stopEngine || 0);
                setPob(response.data[0].pax);
                let crewcount = 0;
                if (response.data[0].crew1Id) {
                    crewcount++;
                }
                if (response.data[0].crew2Id) {
                    crewcount++;
                }
                if (response.data[0].crew3Id) {
                    crewcount++;
                }
                if (response.data[0].crew4Id) {
                    crewcount++;
                }
                setCrewN(crewcount);
                setLoading(false);
            })
            .catch((error) => {
                setAlert({
                    title: 'Feil under lasting!',
                    type: 'error',
                    messages: ['Feil under lasting av mannskapslister'],
                });
                setLoading(false);
            });
    };
    const fetchMissionTypeList = () => {
        setLoading(true);
        Axios.get('v1/missioncat')
            .then((res) => {
                setMissionTypeList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchLogTypeList = () => {
        setLoading(true);
        Axios.get('v1/logtype')
            .then((res) => {
                setLogTypeList(
                    res.data.filter((entry) => entry.showInApp === 1)
                );
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchStatusList = () => {
        setLoading(true);
        Axios.get('v1/status')
            .then((res) => {
                setStatusList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchMissionLog = useCallback(() => {
        if (!missionData.id || missionData.stopTime) return;
        else {
            Axios.get(`v1/missionlog/${missionData.id}`)
                .then((res) => {
                    setMissionLog(res.data);
                })
                .catch((err) => {
                    setMissionLog([]);
                });
        }
    }, [missionData.id, missionData.stopTime]);
    const handlePobInc = () => {
        setPob(pob + 1);
    };
    const handlePobDec = () => {
        if (pob >= 1) {
            setPob(pob - 1);
        }
    };
    const handleStartMission = () => {
        let errors = 0;
        setStartCrew1Helper();
        setStartCrew2Helper();
        setStartCrew3Helper();
        setStartCrew4Helper();
        setStartLocHelper();
        setStartEngHelper();
        setStartMissionTypeHelper();
        if (!startCrew1) {
            setStartCrew1Helper('Du kan ikke starte oppdrag uten båtfører...');
            errors++;
        }
        if (!startCrew2 && !startCrew3 && !startCrew4) {
            setStartCrew2Helper(
                'Du må velge et mannskap i tillegg til kaptein'
            );
            setStartCrew3Helper(
                'Du må velge et mannskap i tillegg til kaptein'
            );
            setStartCrew4Helper(
                'Du må velge et mannskap i tillegg til kaptein'
            );
            errors++;
        }
        if (startLoc === '') {
            setStartLocHelper('Startposisjon kan ikke være tom...');
            errors++;
        }
        if (startEng === '' || startEng < missionData.stopEngine) {
            setStartEngHelper(
                `Motortimer ved start må være høyere enn ${
                    missionData.stopEngine || 0
                }`
            );
            errors++;
        }
        if (!startMissionType) {
            setStartMissionTypeHelper('Du må velge oppdragstype...');
            errors++;
        }
        if (errors === 0) {
            let startData = {};
            if (startCrew1) {
                startData.crew1id = startCrew1.id;
                startData.crew1name = startCrew1.fullName;
            }
            if (startCrew2) {
                startData.crew2id = startCrew2.id;
                startData.crew2name = startCrew2.fullName;
            }
            if (startCrew3) {
                startData.crew3id = startCrew3.id;
                startData.crew3name = startCrew3.fullName;
            }
            if (startCrew4) {
                startData.crew4id = startCrew4.id;
                startData.crew4name = startCrew4.fullName;
            }
            startData.missioncatid = startMissionType.id;
            startData.missioncatname = startMissionType.name;
            startData.starttime = Date.now();
            startData.startengine = startEng;
            startData.startloc = startLoc;
            Axios.post('v1/mission', startData)
                .then((res) => {
                    const mdata = encodeURIComponent(
                        `<rcl>UT</rcl><tur>${
                            startData.missioncatname
                        }</tur><c1>${startData.crew1name}</c1><c2>${
                            startCrew2 ? startCrew2.fullName : null
                        }</c2><c3>${
                            startCrew3 ? startCrew3.fullName : null
                        }</c3><c4>${
                            startCrew4 ? startCrew4.fullName : null
                        }</c4>`
                    )
                        .replace(/!/g, '%21')
                        .replace(/'/g, '%27')
                        .replace(/\(/g, '%28')
                        .replace(/\)/g, '%29')
                        .replace(/\*/g, '%2A')
                        .replace(/~/g, '%7E')
                        .replace(/%20/g, '+');
                    fetchMissionData();
                    Axios.get(
                        `https://agder247.com/rkh/backendrcl/datapawl.php?data=${mdata}&ins=mammamia`
                    )
                        .then((res) => {
                            console.log('Sendt status til Hank sin APP');
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    setAlert({
                        title: 'Feil under oppstart av oppdrag',
                        type: 'error',
                        messages: [err.response.data.msg],
                    });
                    setTimeout(() => {
                        fetchMissionData();
                        setAlert();
                    }, 5000);
                });
        }
    };
    const handleEndMission = () => {
        let errors = 0;
        setStopLocHelper();
        setStopEngHelper();
        setStopDistHelper();
        if (stopLoc === '') {
            setStopLocHelper('Lokasjon ved avslutning kan ikke være blank');
            errors++;
        }
        if (stopEng === '' || stopEng < missionData.startEngine) {
            setStopEngHelper(
                `Motortimer må være satt over ${missionData.startEngine}`
            );
            errors++;
        }
        if (stopDist === '' || stopDist < 0) {
            setStopDistHelper('Avstand kan ikke være blank eller under null');
            errors++;
        }
        if (errors === 0) {
            const stopTime = Date.now();
            const inputData = {
                stoploc: stopLoc,
                stopengine: stopEng,
                stoptime: stopTime,
                distance: stopDist,
            };
            Axios.patch(`v1/mission/${missionData.id}`, inputData)
                .then((res) => {
                    localStorage.removeItem('missionLog');
                    localStorage.removeItem('lastMission');
                    setEndMissionDialogOpen(false);
                    setStopLoc('');
                    fetchMissionData();
                    setAlert({
                        title: 'Oppdraget ble avsluttet',
                        type: 'success',
                        messages: ['Oppdraget ble korrekt avsluttet og lagret'],
                    });
                    setTimeout(() => {
                        setAlert();
                    }, 10000);
                })
                .catch((err) => {
                    console.log(err);
                    setAlert({
                        title: 'Feil under lagring',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under avslutning av oppdrag. Prøv på nytt senere',
                        ],
                    });
                });
        }
    };
    const handleNewStatus = (evt) => {
        saveNewLog(
            missionData.id,
            'STATUS',
            evt.currentTarget.getAttribute('data-status')
        );
        setNewStatusDialogOpen(!newStatusDialogOpen);
    };
    const handleNewLog = () => {
        setLogEntryHelper();
        setLogEntryTypeHelper();
        let errors = 0;
        if (logEntry === '') {
            setLogEntryHelper('Loggføringstekst kan ikke være blank');
            errors++;
        }
        if (!logEntryType) {
            setLogEntryTypeHelper('Du må velge kategori for loggføring.');
            errors++;
        }
        if (errors === 0) {
            saveNewLog(missionData.id, logEntryType.name, logEntry);
            setNewLogEntryDialogOpen(false);
            setLogEntryType(null);
            setLogEntry('');
        }
    };
    const handleChangeMissionCat = () => {
        let errors = 0;
        setStopLocHelper();
        setStopEngHelper();
        setStopDistHelper();
        setStartMissionTypeHelper();
        if (stopLoc === '') {
            setStopLocHelper(
                'Lokasjon ved endring av oppdrag kan ikke være blank'
            );
            errors++;
        }
        if (stopEng === '' || stopEng < missionData.startEngine) {
            setStopEngHelper(
                `Motortimer må være satt over ${missionData.startEngine}`
            );
            errors++;
        }
        if (stopDist === '' || stopDist < 0) {
            setStopDistHelper('Avstand kan ikke være blank eller under null');
            errors++;
        }
        if (!startMissionType) {
            setStartMissionTypeHelper('Ny oppdragstype kan ikke være blank...');
            errors++;
        }
        if (errors === 0) {
            const stopTime = Date.now();
            const inputData = {
                stoploc: stopLoc,
                stopengine: stopEng,
                stoptime: stopTime,
                distance: stopDist,
            };
            Axios.patch(`v1/mission/${missionData.id}`, inputData)
                .then((res) => {
                    let startData = {};
                    if (missionData.crew1Id) {
                        startData.crew1id = missionData.crew1Id;
                        startData.crew1name = missionData.crew1Name;
                    }
                    if (missionData.crew2Id) {
                        startData.crew2id = missionData.crew2Id;
                        startData.crew2name = missionData.crew2Name;
                    }
                    if (missionData.crew3Id) {
                        startData.crew3id = missionData.crew3Id;
                        startData.crew3name = missionData.crew3Name;
                    }
                    if (missionData.crew4Id) {
                        startData.crew4id = missionData.crew4Id;
                        startData.crew4name = missionData.crew4Name;
                    }
                    startData.missioncatid = startMissionType.id;
                    startData.missioncatname = startMissionType.name;
                    startData.starttime = Date.now();
                    startData.startengine = stopEng;
                    startData.startloc = stopLoc;
                    Axios.post('v1/mission', startData)
                        .then((res) => {
                            fetchMissionData();
                            setChangeCatDialogOpen(false);
                            setStartMissionType(null);
                            setAlert({
                                title: 'Oppdragstype endret',
                                type: 'success',
                                messages: [
                                    'Oppdraget ble korrekt avsluttet og og startet på ny',
                                ],
                            });
                            setTimeout(() => {
                                setAlert();
                            }, 10000);
                        })
                        .catch((err) => {
                            setAlert({
                                title: 'Feil under oppstart av oppdrag',
                                type: 'error',
                                messages: [err.response.data.msg],
                            });
                            setTimeout(() => {
                                fetchMissionData();
                                setAlert();
                            }, 5000);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setAlert({
                        title: 'Feil under lagring',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under avslutning av oppdrag. Prøv på nytt senere',
                        ],
                    });
                });
        }
    };
    const handleSaveOfflineLog = () => {
        let offlineCount = offlineLog.length;
        offlineLog.map((logEntry) => {
            Axios.post('v1/missionlog', logEntry)
                .then((res) => {
                    offlineCount--;
                    fetchMissionLog();
                    if (offlineCount === 0) {
                        localStorage.removeItem('offlinelog');
                        setOfflineLog([]);
                        console.log(offlineLog);
                    }
                })
                .catch((err) => {});
        });
    };
    const saveNewLog = (missionid, cat, remark) => {
        const logData = {
            missionid: missionid,
            category: cat,
            remark: remark,
            timestamp: Date.now(),
        };
        Axios.post('v1/missionlog', logData)
            .then((res) => {
                fetchMissionLog();
            })
            .catch((err) => {
                setOfflineLog([...offlineLog, logData]);
                localStorage.setItem(
                    'offlinelog',
                    btoa(JSON.stringify([...offlineLog, logData]))
                );
                setAlert({
                    type: 'error',
                    title: 'Feil under lagring',
                    msg: ['Lagring av logg feilet...Logg lagret lokalt.'],
                });
            });
    };
    useEffect(() => {
        fetchMissionData();
        fetchCrewList();
        fetchMissionTypeList();
        fetchStatusList();
        fetchLogTypeList();
        //fetchHankAppData();
    }, []);
    useEffect(() => {
        fetchMissionLog();
    }, [fetchMissionLog]);
    useEffect(() => {
        if (crewList1.length !== 0) {
            setStartCrew1(
                crewList1[
                    crewList1
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(missionData.crew1Id)
                ] || null
            );
        }
        if (crewList2.length !== 0) {
            setStartCrew2(
                crewList2[
                    crewList2
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(missionData.crew2Id)
                ] || null
            );
        }
        if (crewList3.length !== 0) {
            setStartCrew3(
                crewList3[
                    crewList3
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(missionData.crew3Id)
                ] || null
            );
        }
        if (crewList4.length !== 0) {
            setStartCrew4(
                crewList4[
                    crewList4
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(missionData.crew4Id)
                ] || null
            );
        }
    }, [crewList1, crewList2, crewList3, crewList4, missionData]);
    useEffect(() => {
        if (pob !== missionData.pax) {
            const saveData = {
                pax: pob,
            };
            Axios.patch(`v1/mission/${missionData.id}`, saveData)
                .then((res) => {
                    let newMissionData = missionData;
                    newMissionData.pax = pob;
                    localStorage.setItem(
                        'lastMission',
                        btoa(JSON.stringify(newMissionData))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [pob, missionData]);
    useEffect(() => {
        if (localStorage.getItem('offlinelog')) {
            setOfflineLog(JSON.parse(atob(localStorage.getItem('offlinelog'))));
        }
    }, []);

    if (loading) {
        return <Loader />;
    } else {
        if (missionData && missionData.stopTime) {
            return (
                <React.Fragment>
                    {alert ? (
                        <CustomAlert alert={alert} setAlert={setAlert} />
                    ) : null}
                    {offlineLog.length > 0 ? (
                        <OfflineLogAlert
                            handleSaveOfflineLog={handleSaveOfflineLog}
                        />
                    ) : null}
                    <CrewPickerCard
                        crewList1={crewList1}
                        crewList2={crewList2}
                        crewList3={crewList3}
                        crewList4={crewList4}
                        startCrew1={startCrew1}
                        startCrew2={startCrew2}
                        startCrew3={startCrew3}
                        startCrew4={startCrew4}
                        setStartCrew1={setStartCrew1}
                        setStartCrew2={setStartCrew2}
                        setStartCrew3={setStartCrew3}
                        setStartCrew4={setStartCrew4}
                        startCrew1Helper={startCrew1Helper}
                        startCrew2Helper={startCrew2Helper}
                        startCrew3Helper={startCrew3Helper}
                        startCrew4Helper={startCrew4Helper}
                    />
                    <StartMissionCard
                        startLoc={startLoc}
                        setStartLoc={setStartLoc}
                        startEng={startEng}
                        setStartEng={setStartEng}
                        missionTypeList={missionTypeList}
                        startMissionType={startMissionType}
                        setStartMissionType={setStartMissionType}
                        handleStartMission={handleStartMission}
                        startLocHelper={startLocHelper}
                        startEngHelper={startEngHelper}
                        startMissionTypeHelper={startMissionTypeHelper}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {alert ? (
                        <CustomAlert alert={alert} setAlert={setAlert} />
                    ) : null}
                    {offlineLog.length > 0 ? (
                        <OfflineLogAlert
                            handleSaveOfflineLog={handleSaveOfflineLog}
                        />
                    ) : null}
                    <NewLogEntryDialog
                        newLogEntryDialogOpen={newLogEntryDialogOpen}
                        setNewLogEntryDialogOpen={setNewLogEntryDialogOpen}
                        logEntry={logEntry}
                        setLogEntry={setLogEntry}
                        logEntryType={logEntryType}
                        setLogEntryType={setLogEntryType}
                        logTypeList={logTypeList}
                        logEntryHelper={logEntryHelper}
                        logEntryTypeHelper={logEntryTypeHelper}
                        handleNewLog={handleNewLog}
                    />
                    <NewStatusDialog
                        newStatusDialogOpen={newStatusDialogOpen}
                        setNewStatusDialogOpen={setNewStatusDialogOpen}
                        statusList={statusList}
                        handleNewStatus={handleNewStatus}
                    />
                    <EndMissionDialog
                        missionData={missionData}
                        setEndMissionDialogOpen={setEndMissionDialogOpen}
                        endMissionDialogOpen={endMissionDialogOpen}
                        handleEndMission={handleEndMission}
                        stopLoc={stopLoc}
                        setStopLoc={setStopLoc}
                        stopEng={stopEng}
                        setStopEng={setStopEng}
                        stopDist={stopDist}
                        setStopDist={setStopDist}
                        stopLocHelper={stopLocHelper}
                        stopEngHelper={stopEngHelper}
                        stopDistHelper={stopDistHelper}
                    />
                    <ChangeCatDialog
                        missionData={missionData}
                        setChangeCatDialogOpen={setChangeCatDialogOpen}
                        changeCatDialogOpen={changeCatDialogOpen}
                        handleChangeMissionCat={handleChangeMissionCat}
                        stopLoc={stopLoc}
                        setStopLoc={setStopLoc}
                        stopEng={stopEng}
                        setStopEng={setStopEng}
                        stopDist={stopDist}
                        setStopDist={setStopDist}
                        stopLocHelper={stopLocHelper}
                        stopEngHelper={stopEngHelper}
                        stopDistHelper={stopDistHelper}
                        startMissionTypeHelper={startMissionTypeHelper}
                        missionTypeList={missionTypeList}
                        startMissionType={startMissionType}
                        setStartMissionType={setStartMissionType}
                    />
                    <MissionDataExp
                        missionData={missionData}
                        handlePobInc={handlePobInc}
                        handlePobDec={handlePobDec}
                        setEndMissionDialogOpen={setEndMissionDialogOpen}
                        setChangeCatDialogOpen={setChangeCatDialogOpen}
                        pob={pob}
                        crewN={crewN}
                    />
                    <MissionLogExp
                        missionLog={missionLog}
                        setNewLogEntryDialogOpen={setNewLogEntryDialogOpen}
                        setNewStatusDialogOpen={setNewStatusDialogOpen}
                    />
                </React.Fragment>
            );
        }
    }
};

export default MissionV2;
