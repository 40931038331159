import React from 'react';
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const ChangeCatDialog = (props) => {
    return (
        <Dialog
            id='changeMissionCat'
            open={props.changeCatDialogOpen}
            onClose={() => props.setChangeCatDialogOpen(false)}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Endre oppdragstype</DialogTitle>
            <DialogContent>
                <TextField
                    error={props.stopLocHelper ? true : false}
                    helperText={props.stopLocHelper}
                    fullWidth
                    variant='outlined'
                    label='Lokasjon ved endring av oppdrag'
                    value={props.stopLoc}
                    onChange={(e) => {
                        props.setStopLoc(e.target.value);
                    }}
                    type='text'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    error={props.stopEngHelper ? true : false}
                    helperText={props.stopEngHelper}
                    variant='outlined'
                    label={`Motortimer nå(OBS, må være høyere enn eller lik ${props.missionData.startEngine}`}
                    value={props.stopEng}
                    onChange={(e) => {
                        props.setStopEng(e.target.value);
                    }}
                    type='number'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    error={props.stopDistHelper ? true : false}
                    helperText={props.stopDistHelper}
                    variant='outlined'
                    label='Nautiske mil før endring av oppdrag(Husk å resette)'
                    type='number'
                    value={props.stopDist}
                    onChange={(e) => {
                        props.setStopDist(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                />
                <Autocomplete
                    id='missionCat'
                    options={props.missionTypeList}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.name}
                    value={props.startMissionType}
                    onChange={(e, val) => props.setStartMissionType(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={props.startMissionTypeHelper ? true : false}
                            helperText={props.startMissionTypeHelper}
                            label='Ny Oppdragstype'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={props.handleChangeMissionCat}
                        >
                            Endre oppdrag og start på nytt
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setChangeCatDialogOpen(false);
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeCatDialog;
