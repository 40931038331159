import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grid,
    Divider,
    Button,
} from '@material-ui/core';
import {
    ExpandMore as ExpandMoreIcon,
    Remove as RemoveIcon,
    Add as AddIcon,
} from '@material-ui/icons';
import moment from 'moment';
const MissionDataExp = (props) => {
    return (
        <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='missionDataPanel'
                id='missionDataPanelHeader'
            >
                <Typography variant='h5'>
                    Oppdragsdata og handlinger:
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Båtfører: {props.missionData.crew1Name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Navigatør:{' '}
                                    {props.missionData.crew2Name &&
                                        props.missionData.crew2Name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Førstehjelper:{' '}
                                    {props.missionData.crew3Name &&
                                        props.missionData.crew3Name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Mannskap:{' '}
                                    {props.missionData.crew4Name &&
                                        props.missionData.crew4Name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Oppdragstype:{' '}
                                    {props.missionData.missionCatName}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ border: '1px solid #1f1e1e' }}
                            >
                                <Typography
                                    variant='button'
                                    display='block'
                                    gutterBottom
                                >
                                    Start:{' '}
                                    {moment(props.missionData.startTime).format(
                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container space={3}>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    color='primary'
                                    startIcon={<AddIcon fontSize='large' />}
                                    fullWidth
                                    variant='contained'
                                    size='large'
                                    onClick={props.handlePobInc}
                                >
                                    Legg til
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant='h3'
                                    gutterBottom
                                    align='center'
                                >
                                    POB: {props.crewN} + {props.pob}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    color='secondary'
                                    startIcon={<RemoveIcon fontSize='large' />}
                                    fullWidth
                                    variant='contained'
                                    size='large'
                                    onClick={props.handlePobDec}
                                >
                                    Fjerne
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='secondary'
                            size='large'
                            onClick={() => {
                                props.setEndMissionDialogOpen(true);
                            }}
                        >
                            Avslutt oppdrag
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant='contained'
                            style={{ background: 'gold' }}
                            size='large'
                            onClick={() => {
                                props.setChangeCatDialogOpen(true);
                            }}
                        >
                            Endre oppdragstype
                        </Button>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default MissionDataExp;
